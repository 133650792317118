import type { BaseData, PaginationData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { scrollToAnchor } from '../../utils';
import { api } from '../../utils/api';
import { FilterStore } from '../components/filter/store';
import PaginationStore from '../components/pagination/store';
import { RightSiderStore } from '../components/rightSider/store';
import { FilterItems } from './constant';
import type { Detail, MarketProps, ParentFtCategoryProps, TagsProps } from './interface';

export class ExhibitionHallStore {
  constructor() {
    this.init();
  }

  @observable public loading = false; // 页面loading

  @observable public backgroundUrl = ''; // 背景图

  @observable public jumpUrl = ''; // 跳转H5链接

  @observable public exhibitionHallName = ''; // 展厅名

  @observable public exhibitionHallId = []; // 展厅id

  @observable public exhibitionHallAddress = ''; // 展厅地址

  @observable public category = []; // 类目

  @observable public list = []; // 款式列表、档口列表

  public paginationStore = new PaginationStore({ parent: this }); // 分页store

  public rightSiderStore = new RightSiderStore({ parent: this }); // 分页store

  @observable public totalCount = 0; // 列表总数

  @observable public totalPageCount = 0; // 分页总数

  @observable public invalid = [
    'null',
    '',
  ];

  @action
  public init = () => {
    const search = new URLSearchParams(window.location.href.split('?')[1]);

    // 获取传入参数
    this.exhibitionHallName = search.get('exhibitionHallName');
    this.exhibitionHallId = JSON.parse(search.get('exhibitionHallId'));
    this.exhibitionHallAddress = search.get('exhibitionHallAddress');

    document.title = `${this.exhibitionHallName}-衫海精`;

    // 获取一级分类
    this.getParentFtCategory();

    // 获取风格
    this.getStyle();

    // 获取市场
    this.getGoodsMarket();

    // 重置分页
    this.paginationStore.resetPage();

    // 搜索
    this.onPageChange(1, 50);
    this.getExhibitionHallPic();
  };

  // 获取详情图以及配置
  @action
  public getExhibitionHallPic = async() => {
    const res: BaseData<Detail> = await request({ url: api.getExhibitionHallPic });
    if (res.data?.detailVos?.length > 0) {
      this.backgroundUrl = res.data.detailVos[0].content;
      this.jumpUrl = res.data.detailVos[0].url;
    }
  };

  // 处理前端3级类目
  private dealFtCategory = (categoryList: ParentFtCategoryProps[]) => {
    return categoryList?.map((category) => {
      if (!category.child || category.child.length === 0) {
        return {
          label: category.ftCategoryName,
          value: category.id,
          children: [],
        };
      } else {
        return {
          label: category.ftCategoryName,
          value: category.id,
          children: this.dealFtCategory(category.child),
        };
      }
    });
  };

  // 获取品类
  @action
  public getParentFtCategory = async() => {
    const req = await request<BaseData<ParentFtCategoryProps[]>>({
      url: api.queryMoreCategory,
      method: 'get',
    });
    this.category = req.data;
    const ftCategoryIds = this.dealFtCategory(req.data);
    this.filter.updateFilterItems({ ftCategoryIds });
  };

  @action
  public getFtCategory = (categoryId: number) => {
    let parentFtCategoryId: number,
      ftCategoryIds = [];
    const currentCategory = this.category?.find((v) => v.id === categoryId);
    if (currentCategory) {
      parentFtCategoryId = categoryId;
    } else {
      for (let i = 0; i < this.category.length; i++) {
        const secondList = this.category[i].child;
        const secondCategory = secondList?.find((v) => v.id === categoryId);
        if (secondCategory) {
          parentFtCategoryId = this.category[i].id;
          ftCategoryIds = [categoryId];
          break;
        } else {
          for (let j = 0; j < secondList.length; j++) {
            const thirdCategory = secondList[j].child?.find((v) => v.id === categoryId);
            if (thirdCategory) {
              parentFtCategoryId = this.category[i].id;
              ftCategoryIds = [categoryId];
              break;
            }
          }
        }
      }
    }
    return {
      parentFtCategoryId,
      ftCategoryIds,
    };
  };

  // 获取风格
  @action
  public getStyle = async() => {
    const req = await request<BaseData<TagsProps[]>>({
      url: `${api.getTagGroup}?type=3`,
      method: 'get',
    });

    // 给当前激活tab筛选项改变下拉值
    this.filter.updateFilterItems({
      style: req.data.filter((item) => item.tagGroupId === 10001)[0].tags.map((item) => ({
        label: item.tagName,
        value: item.tagId,
      })),
      applicableSeason: req.data.filter((item) => item.tagGroupId === 10017)[0].tags.map((item) => ({
        label: item.tagName,
        value: item.tagId,
      })),
      applicableAge: req.data.filter((item) => item.tagGroupId === 100019)[0].tags.map((item) => ({
        label: item.tagName,
        value: item.tagId,
      })),
      applicableChannel: req.data.filter((item) => item.tagGroupId === 10015)[0].tags.map((item) => ({
        label: item.tagName,
        value: item.tagId,
      })),
      applicableGender: req.data.filter((item) => item.tagGroupId === 10018)[0].tags.map((item) => ({
        label: item.tagName,
        value: item.tagId,
      })),
    });
  };

  // 获取风格
  @action
  public getGoodsMarket = async() => {
    const req = await request<BaseData<MarketProps[]>>({
      url: api.getGoodsMarket,
      method: 'get',
    });

    // 给当前激活tab筛选项改变下拉值
    this.filter.updateFilterItems({
      marketIds: req.data.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    });
  };

  /**
   * 根据当前选中的tab获取款式\档口
   * @param params 查询条件
   */
  @action
  public getList = async(params: any = {}) => {
    const { priceRange = []} = params;
    this.loading = true;
    const {
      parentFtCategoryId,
      ftCategoryIds,
    } = this.getFtCategory(Number(params.ftCategoryIds));
    try {
      const req = await request<PaginationData>({
        url: '/api/mall/rest/square/anon/product/pageGoodsV2',
        method: 'post',
        data: {
          ...params,
          page: params.page,
          pageSize: params.pageSize,
          minProductPrice: priceRange[0],
          maxProductPrice: priceRange[1],
          galleryId: this.exhibitionHallId,
          sidx: params.sidx.split('-')[0],
          sord: params.sidx.split('-')[1],
          parentFtCategoryId,
          ftCategoryIds,
        },
      });
      this.list = req.data.list;
      this.totalCount = req.data.totalCount;
      this.totalPageCount = req.data.totalPageCount;
    } finally {
      this.loading = false;
    }
  };

  // 改变分页
  @action
  public onPageChange = (page: number, pageSize: number): void => {
    this.getList({
      ...this.filter.params,
      page,
      pageSize,
    });
    scrollToAnchor('root');
  };

  // 筛选
  public filter = new FilterStore({
    filterItems: FilterItems(this),
    handleSearch: () => {
      // 重置分页
      this.paginationStore.resetPage();

      // 搜索
      this.onPageChange(1, 50);
    },
  });
}
