import { Col, Row, Spin } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import { PUBLIC_IMAGE_URL, setLocationHref } from '../../utils';
import { BlankSegment, Footer, FooterLogo, Navigation, PaginationCus, ProductCard, RightSider } from '../components';
import EmptyStatus from '../components/empty';
import { Filter } from '../components/filter';
import styles from './index.less';
import { ExhibitionHallStore } from './store';

const store = new ExhibitionHallStore();

@observer
export default class ExhibitionHall extends React.Component {
  componentDidMount(): void {
    setLocationHref();
  }

  render() {
    return (
      <div className={styles.exhibitionHall}>
        <Navigation className={styles.nav}/>
        <div className={styles.exhibitionHallContent}>
          <div
            className={styles.bg}
          >
            <img
              className={styles.bgImg}
              src={store.backgroundUrl}
            />
            {
              !store.invalid.includes(store.exhibitionHallAddress) && (
                <div
                  className={styles.exhibitionHallNameContainer}
                >
                  <span className={styles.exhibitionHallAddress}>
                    <img
                      className={styles.exhibitionHallAddressIcon}
                      src={`${PUBLIC_IMAGE_URL}address.png`}
                    />
                    {store.exhibitionHallAddress}
                  </span>
                </div>
              )
            }

          </div>
          <div className={styles.listContainer}>
            <Filter store={store.filter}/>
            <Spin spinning={store.loading}>
              {
                store?.loading ? (
                  <BlankSegment/>
                ) : (
                  store.list?.length > 0 ? (
                    <Row
                      className={styles.goodsWrap}
                      gutter={[
                        12,
                        12,
                      ]}
                    >
                      {
                        store.list?.map((item, index) => {
                          const { goodsId } = item;
                          return (
                            <Col
                              key={goodsId}
                            >
                              <ProductCard
                                activityPrice={item.activityPrice}
                                callback={() => {
                                  store.rightSiderStore.getMyStyleWaitNum();
                                }}
                                className={styles.productCard}
                                discountPrice={item.discountPrice}
                                doesAddStyle={item.doesAddStyle}
                                goodsId={item.goodsId}
                                goodsName={item.goodsName}
                                key={nanoid()}
                                mainPic={item.mainPic}
                                picGroupNum={item.picGroupNum}
                                returnRate={item.returnRate}
                                returnable={item.returnable}
                                sellerOuterNo={item.sellerOuterNo}
                                sellingPointTag={item.sellingPointTag}
                                virtualSaleNum={item.virtualSaleNum}
                                virtualSelectNum={item.virtualSelectNum}

                                // visible={item.visible}
                                wholePrice={item.wholePrice}
                              />
                            </Col>
                          );
                        })
                      }
                    </Row>
                  ) : (
                    <div className={styles.emptyDataWrap}>
                      <EmptyStatus/>
                    </div>
                  )

                )
              }
              <PaginationCus
                store={store.paginationStore}
                totalCount={store.totalCount}
                totalPageCount={store.totalPageCount}
              />
            </Spin>
          </div>
        </div>
        <RightSider store={store.rightSiderStore}/>
        <FooterLogo/>
        <Footer/>
      </div>
    );
  }
}
